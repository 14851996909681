import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function ZapietAndTrexity () {
  const shareTitle = 'Zapiet and Trexity partner up'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Zapiet and Trexity partner up to offer Merchants same-day local delivery</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='announcement'>Announcement</span>
                  <span>November 23, 2020</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/zapiet-trexity.jpg" alt="Zapiet + Trexity" />

                <p>If you’ve ever managed a Shopify store, you’ve likely heard of Zapiet. They take an already robust online e-commerce platform and supercharge it to the next level. Offering features as curbside pick ups, local delivery, and pick up windows, Zapiet is no stranger to the e-commerce game.</p>
                <p>Trexity, a flexible, scalable, and cost-effective solution for same-day local delivery currently operates in multiple cities across Canada. It was a no-brainer for Zapiet and Trexity to partner together and offer their clients the best possible experience.</p>

                <blockquote className='quote'>
                  Trexity joins Zapiet as Canada’s national same-day local delivery partner.”
                </blockquote>

                <p>Both parties are excited for the integration between both platforms. Helping local businesses compete with giant operations and keep our communities strong.</p>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/tech-veteran-rob-woodbridge" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Shifting gears: Tech veteran Rob Woodbridge moves into C-suite at delivery platform Trexity</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
